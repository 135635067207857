import * as React from "react";
import Image from "../components/image";
import appleLogo from "../resources/images/apple.svg";
import googlePlayLogo from "../resources/images/google-play.svg";
import rightArrowLogo from "../resources/images/right-arrow.svg";

export interface IBrandFooterProps {
    trackerBusiness: any;
    hasTrackerInfo: boolean;
}

export default class BrandFooter extends React.Component<IBrandFooterProps, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let { trackerBusiness, hasTrackerInfo } = this.props;

        return (
            <div style={{ marginLeft: "15%", marginRight: "15%", marginTop: hasTrackerInfo ? 80 : 0, marginBottom: 48 }}>
                <div className="flex-middle">
                    <div style={{ width: "82%" }}>
                        <div className="flex-middle">
                            {hasTrackerInfo && <div style={{ borderRight: "2px solid #e0e0e0", paddingRight: 104 }}>
                                <Image
                                    src={trackerBusiness.businessImage}
                                    additionalStyles={`
                                max-width: 270px;
                                max-height: 80px;
                                @media (max-width: 1400px) {
                                max-width: 50%;
                                }
                            `}
                                />
                            </div>}
                            <div style={{ paddingLeft: hasTrackerInfo ? 96 : 0, textAlign: "center" }}>
                                <Image
                                    src={require("../resources/images/NieghborlyLogoHubBlueUS.png")}
                                    additionalStyles={`
                                max-width: ${hasTrackerInfo ? '270px' : '50%'};
                                max-height: ${hasTrackerInfo ? '86px' : '52%'};
                                @media (max-width: 1400px) {
                                max-width: ${hasTrackerInfo ? '50%' : '100%'};;
                                }
                            `}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 24 }}>
                    <div>
                        {hasTrackerInfo && <h2 style={{ margin: "15px 0", fontWeight: 700, fontSize: 24 }}>{trackerBusiness.displayText}® is part of the Neighborly® family of home service providers.</h2>}
                        <p style={{ fontSize: 17, color: "#545454", textAlign: "center" }}>Searching through dozens of home service providers is a thing of the past. Rely on Neighborly’s national network of trusted, local home service professionals for all your home service needs.</p>
                    </div>
                    <div className="footer-buttons">
                        <div className="footer-buttons-card">
                            <h3 style={{ fontSize: 16, fontWeight: 700, margin: "0 0 13px", textAlign: "center" }}>Neighborly is all you need to remember</h3>
                            <div className="flex-middle">
                                <a className="footer-button" href="https://www.neighborly.com/" target="_blank" title="Discover Neighborly - opens in new tab">Discover Neighborly
                                    <img src={rightArrowLogo} alt="arrow_right" width="20px" />
                                </a>
                            </div>
                        </div>
                        <div className="footer-buttons-divider" />
                        <div className="footer-buttons-card">
                            <h3 style={{ fontSize: 16, fontWeight: 700, margin: "0 0 13px", textAlign: "center" }}>Download the App</h3>
                            <div className="footer-button-section">
                                <a className="footer-button"
                                    href="https://apps.apple.com/us/app/neighborly/id1504241093" target="_blank" title="App Store - opens in new tab">
                                    <img src={appleLogo} alt="apple_logo" width="18px" />
                                    App Store
                                </a>
                                <a className="footer-button"
                                    href="https://play.google.com/store/apps/details?id=com.nbly.custapp" target="_blank" title="Google play - opens in new tab">
                                    <img src={googlePlayLogo} alt="google_play" width="14px" />
                                    Google play
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 48, color: "grey", fontSize: 16 }}>
                    <p style={{ margin: 0, textAlign: "center" }}>All services are performed by independently owned and operated franchises.</p>
                    <p style={{ margin: 0, textAlign: "center" }}>© {new Date().getFullYear()} Neighborly Company and affiliates. All rights reserved. Neighborly and the Neighborly logos are registered trademarks of Neighborly Assetco LLC.</p>
                </div>

                <div style={{ marginTop: 32, color: "grey" }}>
                    <div className="flex-middle">
                        <div style={{ borderRight: "2px solid grey", paddingRight: 8 }}>
                            <a className="footer-link" href="https://www.neighborly.com/terms-of-use/" target="_blank" title="Terms and Conditions">Terms and Conditions</a>
                        </div>
                        <div style={{ paddingLeft: 8 }}>
                            <a className="footer-link" href="https://www.neighborly.com/privacy-policy/" target="_blank" title="Privacy Policy">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
