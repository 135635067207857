import React from "react";
import Image from "../components/image";

export default class NeighborlyImageLogo extends React.Component {
    render() {
        return (
            <Image
                src={require("../resources/images/NieghborlyLogoWhite.png")}
                className="image-filtered"
                additionalStyles={`
            max-width: 100%;
            maxHeight: auto;

            @media (max-width: 1400px) {
              max-width: 50%;
            }
          `}
            />
        );
    }
}
