import React from "react";
import Column from '../components/column';
import ServicesContainer from '../components/servicesContainer';
import { ServiceEnum } from '../model/company';
import NeighborlyImageLogo from '../components/neighborlyImageLogo';
import BrandFooter from '../components/BrandFooter';

let relatedServices = getViewModelFromModel();

const ErrorPage = (props: any) => {
    let isNewFooter = true;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center', paddingTop: 72 }}>
            <h1 style={{ textAlign: 'center', lineHeight: '42.5px', paddingBottom: 80 }}>Uh oh! Looks like you don't have a current project or contractor to track. </h1>
            {!isNewFooter && <NeighborlyImageLogo />}

            <Column additionalStyles="align-items: center; justify-content: space-between; width: 100%; height: 100%;">
                {isNewFooter ? <BrandFooter trackerBusiness={{}} hasTrackerInfo={false} /> :
                    <ServicesContainer services={relatedServices} />}
            </Column>
        </div>


    );
};

function getViewModelFromModel() {

    let relatedServices: ServiceEnum[] = [];

    return relatedServices;
}

export default ErrorPage; 