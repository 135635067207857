import * as React from "react";
import styled from "styled-components";

export interface ColumnProps {
  additionalStyles?: string;
}

export default class Column extends React.Component<ColumnProps> {
  public render() {
    const ColumnStyled = styled.div`
      display: flex;
      flex-direction: column;
      position: relative;
      ${this.props.additionalStyles}
    `;

    return <ColumnStyled>{this.props.children}</ColumnStyled>;
  }
}
