import * as React from "react";
import styled from "styled-components";

export default function Image(props: any) {
  let ImageStyled = styled.img`
    ${props.additionalStyles}
  `;

  return <ImageStyled {...props} />;
}
