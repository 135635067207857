import NetworkManager from './networkManager';

export default class ServiceManager {
    static getJobById = (id: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const instance = NetworkManager.createManager();
                let result = await instance.get(`ServiceProTypes/ByJobGuidApi/${id}`);
                let services: any[] = [];
                Object.assign(services, (result as any).data);
                resolve(services);
            } catch (error) {
                reject(error);
            }
        });
    };
}
