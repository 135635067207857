import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Tracker from "../pages/tracker";
import ErrorPage from './error';
import UnsubscribePage from './unsubscribe';
import ServiceTitan from "../pages/serviceTitan";

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Route path="/tracker/:jobId" component={Tracker} />
            <Route path="/st" component={ServiceTitan} />
            <Route path="/" exact component={ErrorPage}></Route>
            <Route path="/unsubscribe" exact component={UnsubscribePage}></Route>
        </Router>
    );
};

export default AppRouter;
