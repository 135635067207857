import NetworkManager from './networkManager';
import JobResponse from '../model/jobResponse';

export default class JobManager {
	static getJobById = (id: string) => {
		return new Promise<JobResponse>(async (resolve, reject) => {
			try {
				const instance = NetworkManager.createManager();
				let result = await instance.get(`Tracker/GetTracker/${id}`);
				let job = new JobResponse();
				Object.assign(job, (result as any).data);
				resolve(job);
			} catch (error) {
				reject(error);
			}
		});
	};
}
