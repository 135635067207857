import React from "react";
import Image from "./image";
import Row from "./row";

export interface ICompanyImageRowProps {
    businessImage: string;
}

export default class CompanyImageRow extends React.Component<ICompanyImageRowProps, any> {
    render() {
        return (
            <Row
                additionalStyles="
                margin: 0 auto;
                max-width: 400px; 
                max-height: 200px; 
                height: 200px;
                justify-content: center; 
                align-items: center; 
                margin-bottom: 20px; 
            
                @media (max-width: 1400px) {
                  //width: 30vw;
                  height: auto;
                  margin-top: 10px;
                  padding: 20px;
                }"
            >
                <Image
                    src={this.props.businessImage}
                    additionalStyles="
              display: block;
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;

              @media (max-width: 1400px) {
              }
            "
                />
            </Row>
        );
    }
}
