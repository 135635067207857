import React, { useState, useEffect } from 'react';
import Column from '../components/column';
import Row from "../components/row";
import ServicesContainer from '../components/servicesContainer';
import BrandFooter from '../components/BrandFooter';
import TrackerText from '../view-models/trackerText';
import CompanyImageRow from '../components/companyImageRow';
import NeighborlyImageLogo from '../components/neighborlyImageLogo';
import TrackerArrows from '../components/trackerArrows';
import TrackerImage from "../components/trackerImage";
import JobManager from '../api/jobManager';
import ServiceManager from '../api/serviceManager';
import API_CONSTANTS from '../constants/api';
import Business from '../view-models/business';
import businessTemplates from '../view-models/businessTemplates';
import JobResponse from '../model/jobResponse';
import WarningMessage from '../components/WarningMessage';
import { RouteComponentProps } from 'react-router';
import ServiceProListComp from '../components/ServiceProListComp'

interface MatchParams {
	jobId: string;
}

interface ITrackerProps extends RouteComponentProps<MatchParams> { }

const Tracker: React.FC<ITrackerProps> = (props) => {
	// This is mocking data from the API, as long as the API follows the right data format then this should work
	let [requestStatus, setRequestStatus] = useState(API_CONSTANTS.LOADING_STATUSES.LOADING);
	let [requestResponse, setRequestResponse] = useState(null);
	let [services, setServices] = useState();

	// Get the current JobId from the url
	let currentJobId = props.match.params.jobId;
	checkJobIdForValidity(currentJobId, requestStatus, setRequestStatus, setRequestResponse);

	// Use effect with an empty list argument acts like componentDidMount
	useEffect(() => {
		getInitialJobDataFromAPI(currentJobId, setRequestResponse, setRequestStatus, setServices, requestStatus, requestResponse, services);
	}, []);

	// This is still getting dummy data  from the file system
	let { business, trackerText, relatedServices } = getViewModelFromModel(currentJobId, requestResponse, requestStatus, services);
	let newFooterBrands = ["ASV", "MRR", "MRE"];

	return (
		<div className="App">
			{requestStatus === API_CONSTANTS.LOADING_STATUSES.LOADED && (
				<Column additionalStyles="
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    display: initial !important;
                    position: relative;
                    width: 100%;
                    height: 100%;
                ">
					{trackerText.trackerStep === 5 && <WarningMessage text="This job has been cancelled. Please contact your franchise if you believe this is an error." />}
					{trackerText.trackerStep === 6 && <WarningMessage text="This job has been put on hold. Please contact your franchise if you believe this is an error." />}
					<div className="row">
						<div className="companyImageContainer">
							<CompanyImageRow businessImage={business.businessImage} />
						</div>
						<div className="trackerImageContainer">
							{(trackerText.trackerStep === 1 && trackerText.trackerPro === '') || trackerText.trackerStep === 4 ? <TrackerImage imageURL={business.vanImage} /> : ""}
							{(trackerText.trackerStep === 1 && trackerText.trackerPro !== '') || trackerText.trackerStep === 2 || trackerText.trackerStep === 3 ?
								(trackerText.techImageFromApi === "00000000-0000-0000-0000-000000000000") ?
									<TrackerImage imageURL={business.vanImage} />
									: (<div>
										<TrackerImage imageURL={API_CONSTANTS.NODE_ENV === "production" ?
											("https://api2.dwyergroup.com/Core/v5/WebImages/" + trackerText.techImageFromApi + "/Render") :
											("https://api2-test.dwyergroup.com/Core/v5/WebImages/" + trackerText.techImageFromApi + "/Render")
										} />
									</div>)
								: ""}
						</div>
					</div>
					<Row
						additionalStyles="
                        "
					>
						<TrackerArrows trackerText={trackerText} trackerBusiness={business} />
					</Row>
					{(trackerText.trackerStep === 1 && trackerText.trackerPro !== '') || trackerText.trackerStep === 2 || trackerText.trackerStep === 3 ?
						(trackerText.techImageFromApi === "00000000-0000-0000-0000-000000000000") ?
							""
							: (<div>
								<ServiceProListComp jobGuid={currentJobId} concept={business} />
							</div>)
						: ""}

					{newFooterBrands.includes(business.conceptCode) ? <BrandFooter trackerBusiness={business} hasTrackerInfo={true} /> :
						<>
							<NeighborlyImageLogo />
							<ServicesContainer services={relatedServices} />
						</>}
				</Column>
			)}

			{requestStatus === API_CONSTANTS.LOADING_STATUSES.LOADING && (
				<Column>
					<h1>Loading!</h1>
				</Column>
			)}

			{requestStatus === API_CONSTANTS.LOADING_STATUSES.ERROR && (
				<Column>
					<h1>{requestResponse && requestResponse.toString()}</h1>
				</Column>
			)}
		</div>
	);
};

export default Tracker;

function checkJobIdForValidity(currentJobId: string, requestStatus: string, setRequestStatus: React.Dispatch<React.SetStateAction<string>>, setRequestResponse: React.Dispatch<any>) {
	const currentJobIdIsInvalid = typeof currentJobId !== 'string' && requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR;
	if (currentJobIdIsInvalid) {
		setRequestStatus(API_CONSTANTS.LOADING_STATUSES.ERROR);
		setRequestResponse(new Error('Invalid Job ID. Please enter a valid Job ID in the URL.'));
	}
}

function getInitialJobDataFromAPI(
	currentJobId: string,
	setRequestResponse: React.Dispatch<any>,
	setRequestStatus: React.Dispatch<React.SetStateAction<string>>,
	setServices: React.Dispatch<React.SetStateAction<any>>,
	requestStatus: any,
	requestResponse: any,
	services: any
) {
	JobManager.getJobById(currentJobId)
		.then((response) => {
			if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.LOADED);
			if (requestResponse === null) setRequestResponse(response);
		})
		.catch((error) => {
			console.log('error:....', error);
			if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.ERROR);
			if (requestResponse === null) setRequestResponse(error);
		});
	// ServiceManager.getJobById(currentJobId)
	//     .then((response: any) => {
	//         var companyList: any[] = [];
	//         response.map((item: {}) => (
	//             companyList.push(Object.values(item)[1])
	//         ));
	//         if (services === undefined) setServices(companyList);
	//     })
	//     .catch((error) => {
	//         console.log('error:....', error);
	//         if (services === undefined) setServices(error);
	//     });
}

function getViewModelFromModel(currentJobId: string, response: JobResponse, requestStatus: string, services: []) {
	let trackerText = new TrackerText();
	if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR && response && currentJobId) {
		trackerText.generateTextFromServerResponse(currentJobId, response);
	}

	let business = new Business();
	if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR && response) Object.assign(business, (businessTemplates as any)[response.conceptCode]);

	let relatedServices: any[] = [];
	if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR && response) {

		relatedServices = services;
	}

	return { business, trackerText, relatedServices };
}
