import NetworkManager from './networkManager';
import JobResponse from '../model/jobResponse';

export default class GetTrackerByPos {
	static getJobByPos = (jobId: string, tenantId:string) => {
		return new Promise<JobResponse>(async (resolve, reject) => {
			try {
				const instance = NetworkManager.createManager();
				let result = await instance.get(`Tracker/GetTrackerByPos?jobId=${jobId}&tenantId=${tenantId}`);
				let job = new JobResponse();
				Object.assign(job, (result as any).data);
				resolve(job);
			} catch (error) {
				reject(error);
			}
		});
	};
}
