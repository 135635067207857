import JobResponse from '../model/jobResponse';

export default class TrackerText {
	firstTrackerText: string = 'Default';
	secondTrackerText: string = 'Default';
	thirdTrackerText: string = 'Default';
	fourthTrackerText: string = 'Default';
	fifthTrackerText: string = 'Default';
	trackerStep: number = 0;
	techImageFromApi: string = 'Default';
    postalCode: string = 'Default';
    trackerPro: string = 'Default';

	fillWithDummyData = () => {
		this.firstTrackerText = 'Your Job is Scheduled for Monday Morning 5/6/2019';
		this.secondTrackerText = 'Your Job is Next in Line';
		this.thirdTrackerText = 'Your Service Professional is on the way';
		this.fourthTrackerText = 'Your service professional has completed his job.';
	};

	generateTextFromServerResponse = (currentJobId: string, response: JobResponse) => {
		console.log('TCL: TrackerText -> generateTextFromServerResponse -> response', response);

        this.firstTrackerText = `Your appointment has been scheduled.`;
        this.secondTrackerText = 'Your service professional has been assigned.'
		this.thirdTrackerText = `Your service professional is on the way.`;
		this.fourthTrackerText = `Your service professional has arrived.`;
		this.fifthTrackerText = `Your service professional has completed today's appointment.`;

        this.trackerStep = response.jobStatus;
        this.trackerPro = response.proFirstName;

		this.techImageFromApi = response.proWebImageGuid;
		this.postalCode = response.postalCode;
	};

	private getFormattedDate(date: Date): string {
		return `${this.pad(date.getMonth(), 2)}/${this.pad(date.getDay(), 2)}/${date.getFullYear()} at ${date.toLocaleString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		})}`;
	}

	private pad(stringToPad: any, totalWidth: number, padding?: any) {
		padding = padding || '0';
		stringToPad = stringToPad + '';
		return stringToPad.length >= totalWidth ? stringToPad : new Array(totalWidth - stringToPad.length + 1).join(padding) + stringToPad;
	}
}
