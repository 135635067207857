import * as React from "react";
import Row from "./row";
import styled from "styled-components";
import colors from "../constants/colors";
import ServiceIcon from "./serviceIcon";

export interface IServiceRowProps {
    text: string;
    link: string;
    iconName?: string;
    display: boolean;
}

interface ILinkDisplayProps {
    display: string;
}

export default class ServiceRow extends React.Component<IServiceRowProps, any> {
    public render() {
        const TextStyled = styled.p`
            color: ${colors.BLUE_PRIMARY};
            font-weight: bold;

            @media (max-width: 1000px) {
                //font-size: 2vw;
            }
        `;

        const Link = styled.a`
            text-decoration-color: transparent;
            width: 33%;
            display: ${(props: ILinkDisplayProps) => props.display};

            @media (max-width: 1000px) {
                //width: 30vw;
                margin-left: 3.3333vw;
            }

            @media (max-width: 800px) {
                width: 44vw;
                margin-left: 6vw;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        `;

        return (
            <Link href={this.props.link} target="_blank" display={this.props.display ? "inline" : "none"}>
                <Row additionalStyles="align-items: center;">
                    <ServiceIcon iconName={this.props.iconName} />
                    <TextStyled>{this.props.text}</TextStyled>
                </Row>
            </Link>
        );
    }
}
