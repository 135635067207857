const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default {
    API_BASE_URL: BASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    LOADING_STATUSES: {
        LOADING: "loading",
        LOADED: "loaded",
        ERROR: "error"
    }
};
