import * as React from "react";
import styled from "styled-components";
import colors from "../constants/colors";

export interface ITrackerArrowProps {
    textContent: String;
    isFilled: boolean;
}

export default class TrackerArrow extends React.Component<ITrackerArrowProps, any> {
    public render() {
        const TrackerArrowStyled = styled.div`
            background-color: ${this.props.isFilled ? colors.BLUE_DARK : colors.BLUE_PRIMARY};
            height: 150px;
            width: 100%;
            color: white;
            position: relative;
            -webkit-clip-path: polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 47%, 0% 0%);
            clip-path: polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 47%, 0% 0%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -1%;

            //@media (max-width: 1400px) {
            //    height: 15vw;
            //}
        `;

        const TrackerText = styled.p`
            color: ${colors.TEXT_LIGHT};
            text-align: center;
            margin: 0px 20%;
            font-size: 20px;

            //@media (max-width: 1400px) {
            //    font-size: 1.75vw;
            //}
        `;

        return (
            <TrackerArrowStyled>
                <TrackerText>{this.props.textContent}</TrackerText>
            </TrackerArrowStyled>
        );
    }
}
