import * as React from "react";
import styled from "styled-components";

export interface WarningMessageProps {
    additionalStyles?: string;
    text?: string;
}

export default class WarningMessage extends React.Component<WarningMessageProps> {
    public render() {
        const WarningMessage = styled.div`
            width: 90%;
            max-width: 400px;
            margin: 0px auto;
            background-color: #fff3cd;
            color: #856404;
            border-radius: 5px;
            border: 1px solid #ffeeba;
            padding: 10px;
            margin-top: 10px;
            font-weight: 500;
            position: fixed;
            top: 10px;
            left: 20px;
        `;

        return <WarningMessage>{this.props.text}</WarningMessage>;
    }
}
