export default class ServicePro {
    franchiseId: number;
    scheduledOn: string;
    completedOn: string;
    syncJobStatusTypeID: number;
    franchiseTechnicianBios: TechnicianBios[] = []
}


export class TechnicianBios {
    webImage: WebImage;
    franchiseProfileEmployeeBioId: number;
    franchiseId: number;
    aspNetUserId: string
    createdDateTime: string
    createdUser: string
    updatedDateTime: string
    updatedUser: string
    deletedDateTime: string;
    displayName: string;
    description: string;
    webImageId: number
}

export class WebImage {
    webImageId: number;
    webImageGuid: string;
    webImageTypeId: number
    createdDateTime: string;
    createdUser: string;
    fileName: string;
    fileExtension: string;
    fileContentLength: number;
    externalUrl: string;
    fileDescription: string;
    franchiseId: number;
}