// On the frontend, I am expecting the user to navigate to a URL such as tracker.textaneighbor.com?jobId=25
// I will then take this jobId from the URL to use in the subsequent API call
// The API call will refrence an endpoint written by Chad such as TaNApi.dwyer.com
// In the body, I will include one header:
// The header will be called JobId and reference the JobId I glean from the URL, this example being 25
// This is the response that I expect to receive from this API call

export default class JobResponse {
    public conceptCode: string = "";
    public proWebImageGuid: string = "";
    public postalCode: string = "";
    public jobStatus: number = 0;
    public proFirstName: string = "";
}
