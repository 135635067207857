import NetworkManager from './networkManager';
import ServicePro from '../model/serviceProResponse';

export default class GetServiceProAPI {
    static getServicePro = (id: string) => {
        return new Promise<ServicePro>(async (resolve, reject) => {
            try {
                const instance = NetworkManager.createManager();
                let result = await instance.get(`EmployeeBios/GetEmployeeBiosByJobGuid?job_guid=${id}`);
                let servicePro = new ServicePro();
                Object.assign(servicePro, (result as any).data);
                resolve(servicePro);
            } catch (error) {
                reject(error);
            }
        });
    };
}
