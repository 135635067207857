import React from "react";
import Column from "./column";
import Row from "./row";
import TrackerImage from "./trackerImage";
import TrackerArrow from "./trackerArrow"; 
import ReactDOM from "react-dom";


export interface ITrackerArrowsProps {
    trackerBusiness: any;
    trackerText: any;
}

export default class TrackerArrows extends React.Component<ITrackerArrowsProps, any> {

    componentDidMount = () => {
        var nativeElement = document.querySelector('.entry-content');
        if (nativeElement) {
            var scrollwidth = nativeElement.scrollWidth;
            nativeElement.scroll({top: 0, left: scrollwidth});
        }

    }


    render() {

        console.log("===============================");
        console.log(this.props.trackerBusiness);
        console.log(this.props.trackerText);
        console.log("===============================");

        return (
            <Row
                className='entry-content'
                additionalStyles="
                    padding: 0px 20px;
                    padding-bottom: 20px;
                    width: 100%;

                    @media (max-width: 1400px) {
                        width: 100vw;
                        overflow: scroll;
                        margin: 0 10px;
                    }
                "
            >
                {this.props.trackerText.trackerStep >= 1 ? 
                    <Column additionalStyles="width:20%; justify-content: flex-end; @media (max-width: 1400px) {{
                            min-width: 300px;                    
                        }">                        
                        <TrackerArrow isFilled={this.props.trackerText.trackerStep >= 1 && this.props.trackerText.trackerPro !== ""} textContent={this.props.trackerText.firstTrackerText} />
                    </Column> 
                : <div></div>}
                {this.props.trackerText.trackerStep >= 1 && this.props.trackerText.trackerPro !== "" ? 
                    <Column additionalStyles="width:20%; justify-content: flex-end; @media (max-width: 1400px) {{
                            min-width: 300px;
                        }">
                        <TrackerArrow isFilled={this.props.trackerText.trackerStep > 1} textContent={this.props.trackerText.secondTrackerText} />
                    </Column>
                : <div></div>}
                {this.props.trackerText.trackerStep >= 2 ? 
                    <Column additionalStyles="width:20%; justify-content: flex-end; @media (max-width: 1400px) {{
                        min-width: 300px;
                        }">                  
                        <TrackerArrow isFilled={this.props.trackerText.trackerStep > 2} textContent={this.props.trackerText.thirdTrackerText} />
                    </Column>
                : <div></div>}
                {this.props.trackerText.trackerStep >= 3 ? 
                    <Column additionalStyles="width:20%; justify-content: flex-end; @media (max-width: 1400px) {
                            min-width: 300px;
                        }">
                        <TrackerArrow isFilled={this.props.trackerText.trackerStep > 3} textContent={this.props.trackerText.fourthTrackerText} />
                    </Column>
                : <div></div>}
                {this.props.trackerText.trackerStep >= 4 ? 
                    <Column additionalStyles="width:20%; justify-content: flex-end; @media (max-width: 1400px) {
                            min-width: 300px;
                        }">
                        <TrackerArrow isFilled={this.props.trackerText.trackerStep > 4} textContent={this.props.trackerText.fifthTrackerText} />
                    </Column>
                : <div></div>}

            </Row>
        );
    }
}
