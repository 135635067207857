import * as React from "react";
import Row from "./row";
import ServiceRow from "./serviceRow";
import { ICON_NAMES } from "./serviceIcon";
import { ServiceEnum } from "../model/company";

export interface IServicesContainerProps {
    services: ServiceEnum[];
}

export default class ServicesContainer extends React.Component<IServicesContainerProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            services: this.props.services
        }
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.services !== this.state.services) {
            this.setState({ services: this.props.services });
        }
    }
    render() {
        let services = [];
        { this.state.services !== undefined ? services = this.state.services : services = [] }
        return (
            <Row additionalStyles="width: 100%; justify-content: space-between; max-width: 1000px; margin: 0px auto; flex-wrap: wrap;
                @media (max-width: 600px) {
                max-width: 300px;
                }">
                <ServiceRow
                    display={services.length > 0 ? services.includes("Appliance Repair") : true}
                    text="Appliance Repair"
                    link="https://www.neighborly.com/services/appliance-repair"
                    iconName={ICON_NAMES.ICON_APPLIANCE_REPAIR}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Auto Glass Repair & Install") : true}
                    text="Auto Glass Repair & Install"
                    link="https://www.neighborly.com/services/auto-glass-repair-install"
                    iconName={ICON_NAMES.ICON_AUTO_GLASS_REPAIR}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Carpet Cleaning") : true}
                    text="Carpet Cleaning"
                    link="https://www.neighborly.com/services/carpet-cleaning"
                    iconName={ICON_NAMES.ICON_CARPET_CLEANING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Electrical") : true}
                    text="Electrical"
                    link="https://www.neighborly.com/services/electrical"
                    iconName={ICON_NAMES.ICON_ELECTRICAL}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Fire & Water Damage") : true}
                    text="Fire & Water Damage"
                    link="https://www.neighborly.com/services/fire-water-damage"
                    iconName={ICON_NAMES.ICON_FIRE_WATER}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Glass Repair & Installation") : true}
                    text="Glass Repair & Installation"
                    link="https://www.neighborly.com/services/glass-repair-installation"
                    iconName={ICON_NAMES.ICON_GLASS_REPAIR}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Handyman Services") : true}
                    text="Handyman Services"
                    link="https://www.neighborly.com/services/handyman-services"
                    iconName={ICON_NAMES.ICON_HANDYMAN_SERVICES}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Heating & AC") : true}
                    text="Heating & AC"
                    link="https://www.neighborly.com/services/heating-ac"
                    iconName={ICON_NAMES.ICON_HEATING_AC}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Holiday Lighting") : true}
                    text="Holiday Lighting"
                    link="https://www.neighborly.com/services/holiday-lighting"
                    iconName={ICON_NAMES.ICON_HOLIDAY_LIGHTING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Home Improvement") : true}
                    text="Home Improvement"
                    link="https://www.neighborly.com/services/home-improvement"
                    iconName={ICON_NAMES.ICON_HOME_IMPROVEMENT}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("House Cleaning") : true}
                    text="House Cleaning"
                    link="https://www.neighborly.com/services/house-cleaning"
                    iconName={ICON_NAMES.ICON_HOUSE_CLEANING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Indoor Air Quality") : true}
                    text="Indoor Air Quality"
                    link="https://www.neighborly.com/services/indoor-air-quality"
                    iconName={ICON_NAMES.ICON_INDOOR_AIR_QUALITY}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Landscaping & Lawn Care") : true}
                    text="Landscaping & Lawn Care"
                    link="https://www.neighborly.com/services/landscaping-lawn-care"
                    iconName={ICON_NAMES.ICON_LANDSCAPING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Mosquito Control") : true}
                    text="Mosquito Control"
                    link="https://www.neighborly.com/services/mosquito-control"
                    iconName={ICON_NAMES.ICON_MOSQUITO_CONTROL}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Painting") : true}
                    text="Painting"
                    link="https://www.neighborly.com/services/painting"
                    iconName={ICON_NAMES.ICON_PAINTING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Plumbing") : true}
                    text="Plumbing"
                    link="https://www.neighborly.com/services/plumbing"
                    iconName={ICON_NAMES.ICON_PLUMBING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Power Washing") : true}
                    text="Power Washing"
                    link="https://www.neighborly.com/services/power-washing"
                    iconName={ICON_NAMES.ICON_POWER_WASHING}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Snow Removal") : true}
                    text="Snow Removal"
                    link="https://www.neighborly.com/services/snow-removal"
                    iconName={ICON_NAMES.ICON_SNOW_REMOVAL}
                />
                <ServiceRow
                    display={services.length > 0 ? services.includes("Window Cleaning") : true}
                    text="Window Cleaning"
                    link="https://www.neighborly.com/services/window-cleaning"
                    iconName={ICON_NAMES.ICON_WINDOW_CLEANING}
                />
            </Row>
        );
    }
}
