import JobResponse from "./jobResponse";
import { ServiceEnum } from "./company";

export default function getExampleJob() {
    let response = new JobResponse();

    Object.assign(response, {
        jobStatus: "Scheduled"
    });

    return response;
}

// export function getJobWithEmptySchedule() {
//     let job = getExampleJob();

//     job.technician.jobs = [];

//     return job;
// }

// export function getJobWithOneJobInScheduleThatIsNotCurrentJobAndTechIsNotAtJob() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 0;

//     job.technician.jobs = [
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobWithOneJobInScheduleThatIsNotCurrentJobAndTechIsAtJob() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobScheduleWithOneJobThatIsCurrentJob() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobScheduleWithTwoJobWhereSecondIsCurrentJobAndTechHasNotCompletedAnyJob() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 0;

//     job.technician.jobs = [
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getScheduleWhereCurrentJobIsNotInSchedule() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 100;

//     job.technician.jobs = [
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 61,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 621,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 632,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobThatIsNotNextInSchedule() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 102;

//     job.technician.jobs = [
//         {
//             jobId: 102,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobScheduleThatIsNextAndCompleted() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 100;

//     job.technician.jobs = [
//         {
//             jobId: 102,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getJobScheduleWithTwoJobWhereCurrentIsNextButPreviousIsNotComplete() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 100;

//     job.technician.jobs = [
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getScheduleWithTechAtJob() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getScheduleWithOneJobBeingCurrentAndComplete() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         }
//     ];

//     return job;
// }

// export function getScheduleWhereCurrentJobIsCompleteAndTechIsAtJobStill() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 102,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         }
//     ];

//     return job;
// }

// export function getScheduleWhereTechIsCompleteAndMultipleJobsAfter() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 526;

//     job.technician.jobs = [
//         {
//             jobId: 102,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 252,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 982,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }

// export function getScheduleWhereTechIsCompleteAndMultipleJobsAfterAndHasLeft() {
//     let job = getExampleJob();

//     job.technician.currentJobSiteId = 252;

//     job.technician.jobs = [
//         {
//             jobId: 102,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 100,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 526,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: true
//         },
//         {
//             jobId: 252,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         },
//         {
//             jobId: 982,
//             jobScheduledDateTime: new Date(new Date().getTime() + 30 * 60000),
//             isCompleted: false
//         }
//     ];

//     return job;
// }
