import * as React from "react";
import styled from "styled-components";

export interface RowProps {
  additionalStyles?: string;
  className?: string;
  children: JSX.Element[] | JSX.Element | string;
}

export default class Row extends React.Component<RowProps> {
  public render() {
    const RowStyled = styled.div`
      display: flex;
      flex-direction: row;
      ${this.props.additionalStyles}
    `;

    return <RowStyled className={this.props.className}>{this.props.children}</RowStyled>;
  }
}
