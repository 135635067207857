import * as React from "react";
import styled from "styled-components";

export interface IVanImageProps {
  imageURL: any;
}

export default class VanImage extends React.Component<IVanImageProps> {
  public render() {
    const Van = styled.img`
      display: block;
      max-width: 70%;
      max-height: 150px;
      width: auto;
      height: auto;
      margin: 5px auto;

      @media (max-width: 1000px) {
        //max-height: 12vw;
        max-width: 60%;
        margin: 2px auto;
      }
    `;

    return <Van src={this.props.imageURL} />;
  }
}
