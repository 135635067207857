let rootUrl = 'https://dwyerc4staging.blob.core.windows.net/assets/';
export default {
	MRA: {
		conceptCode: 'MRA',
		businessImage: `${rootUrl}8.png`,
		vanImage: require('../resources/images/MrAppliance/van.png'),
		technicianImage: require('../resources/images/MrAppliance/tech.png'),
		displayText: 'Mr. Appliance'
	},
	FSP: {
		conceptCode: 'FSP',
		businessImage: `${rootUrl}4.png`,
		vanImage: require('../resources/images/FiveStar/van.png'),
		technicianImage: require('../resources/images/FiveStar/tech.png'),
		displayText: 'Five Star Painting'
	},
	MOJ: {
		conceptCode: 'MOJ',
		businessImage: `${rootUrl}18.png`,
		vanImage: require('../resources/images/MosquitoJoe/van.png'),
		technicianImage: require('../resources/images/MosquitoJoe/tech.png'),
		displayText: 'Mosquito Joe'
	},
	GUY: {
		conceptCode: 'GUY',
		businessImage: `${rootUrl}6.png`,
		vanImage: require('../resources/images/GroundGuys/van.png'),
		technicianImage: require('../resources/images/GroundGuys/tech.png'),
		displayText: 'The Grounds Guys'
	},
	MDG: {
		conceptCode: 'MDG',
		businessImage: `${rootUrl}5.png`,
		vanImage: require('../resources/images/GlassDoctor/van.png'),
		technicianImage: require('../resources/images/GlassDoctor/tech.png'),
		displayText: 'Glass Doctor'
	},
	WDG: {
		conceptCode: 'WDG',
		businessImage: `${rootUrl}16.png`,
		vanImage: require('../resources/images/WindowGenie/van.png'),
		technicianImage: require('../resources/images/WindowGenie/tech.png'),
		displayText: 'Window Genie'
	},
	RBW: {
		conceptCode: 'RBW',
		businessImage: `${rootUrl}11.png`,
		vanImage: require('../resources/images/Rainbow/van.png'),
		technicianImage: require('../resources/images/Rainbow/tech.png'),
		displayText: 'Rainbow International'
	},
	ASV: {
		conceptCode: 'ASV',
		businessImage: `${rootUrl}3.png`,
		vanImage: require('../resources/images/AirServ/van.png'),
		technicianImage: 'https://i.imgur.com/rYMOYcZ.png',
		displayText: 'Aire Serv'
	},
	MHM: {
		conceptCode: 'MHM',
		businessImage: require('../resources/images/MyHandyman/logo.png'),
		vanImage: require('../resources/images/MyHandyman/van.png'),
		technicianImage: require('../resources/images/MyHandyman/tech.png'),
		displayText: 'Mr. Handyman'
	},
	MRR: {
		conceptCode: 'MRR',
		businessImage: `${rootUrl}10.png`,
		vanImage: require('../resources/images/MrRooter/van.png'),
		technicianImage: require('../resources/images/MrRooter/tech.png'),
		displayText: 'Mr. Rooter'
	},
	MRE: {
		conceptCode: 'MRE',
		businessImage: `${rootUrl}9.png`,
		vanImage: require('../resources/images/MrElectric/van.png'),
		technicianImage: require('../resources/images/MrElectric/tech.png'),
		displayText: 'Mr. Electric'
	},
	MLY: {
		conceptCode: 'MLY',
		businessImage: `${rootUrl}1.png`,
		vanImage: require('../resources/images/MollyMaid/van.png'),
		technicianImage: require('../resources/images/MollyMaid/tech.png'),
		displayText: 'Molly Maid'
	},
	DVW: {
		conceptCode: 'DVW',
		businessImage: `${rootUrl}24.png`,
		vanImage: `${rootUrl}24_van.png`,
		technicianImage: "",
		displayText: 'Dryer Vent Wizard'
	},
	HMS: {
		conceptCode: 'HMS',
		businessImage: `${rootUrl}25.png`,
		vanImage: `${rootUrl}25_van.png`,
		technicianImage: "",
		displayText: 'HouseMaster'
	},
	SHG: {
		conceptCode: 'SHG',
		businessImage: `${rootUrl}26.png`,
		vanImage: `${rootUrl}26_van.png`,
		technicianImage: "",
		displayText: 'ShelfGenie'
	},
	PDS: {
		conceptCode: 'PDS',
		businessImage: `${rootUrl}27.png`,
		vanImage: `${rootUrl}27_van.png`,
		technicianImage: "",
		displayText: 'Precision Door Services'
	}
};
