import Axios from "axios";
import API_SETTINGS from "../constants/api";
import getExampleJob from "../model/exampleJobResponse";

const IS_TESTING = false;
const TESTING_ERROR = false;
const TEST_TIMEOUT = 1000;

export default class NetworkManager {
    static createManager() {
        if (IS_TESTING) {
            return {
                get() {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            TESTING_ERROR ? reject(new Error("Something went wrong.")) : resolve(getExampleJob());
                        }, TEST_TIMEOUT);
                    });
                }
            };
        } else {
            return Axios.create({
                baseURL: API_SETTINGS.API_BASE_URL,
                timeout: 30000
            });
        }
    }
}
