import React, { useState, useEffect } from 'react';
import Column from '../components/column';
import ServicesContainer from '../components/servicesContainer';
import NeighborlyImageLogo from '../components/neighborlyImageLogo';
import API_CONSTANTS from '../constants/api';
import qs from 'query-string';
import axios from 'axios';
import ServiceManager from "../api/serviceManager";
import BrandFooter from '../components/BrandFooter';


const UnsubscribePage: React.FC<any> = (props) => {
    let [requestStatus, setRequestStatus] = useState(API_CONSTANTS.LOADING_STATUSES.LOADING);
    let [requestResponse, setRequestResponse] = useState();
    let [responseStatus = 0, setResponseStatus] = useState();
    const parsed = qs.parse(props.location.search);
    let currentJobId = parsed.jobGuid;
    let email = parsed.email;
    let isNewFooter = true;

    useEffect(() => {
        getInitialJobDataFromAPI(currentJobId, setRequestResponse, setRequestStatus, requestStatus, requestResponse);
    }, []);

    let { relatedServices } = getViewModelFromModel(currentJobId, requestStatus, requestResponse);

    return (
        <div style={{ display: 'flex', textAlign: "center", flexDirection: 'column', width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center', paddingTop: '120px' }}>
            <p style={{
                color: '#267DBF',
                fontSize: '25px',
                fontWeight: 400,
                margin: 0
            }}>Communication Preference</p>
            <p style={{
                color: '#267DBF',
                fontSize: '20px',
                fontWeight: 'bold',
                margin: 0
            }}>{email}</p>
            <p style={{
                color: '#267DBF',
                fontSize: '20px',
                fontStyle: 'italic',
                margin: 0
            }}>if this is not your email address, please ignore this page.</p>
            <button onClick={() => {
                axios.post('https://tan-api-stage.azurewebsites.net/api/Notifications/Email/Unsubscribe', {
                    emailContactDetail: email,
                    syncJobGuid: currentJobId
                }).then((response: any) => {
                    setResponseStatus(response.status)
                }).catch((response: any) => {
                    setResponseStatus(response.status)
                })
            }} style={{
                color: 'white',
                backgroundColor: '#267DBF',
                border: '3px solid blue',
                borderRadius: '3px',
                fontSize: '20px',
                cursor: 'pointer',
                padding: '8px 30px',
                margin: '5px 0',
                display: responseStatus !== 0 ? 'none' : ''
            }}>Unsubscribe</button>
            {responseStatus === 200 && (
                <p style={{
                    color: '#267DBF',
                    fontSize: '40px',
                    fontWeight: 'bold',
                    margin: 0
                }}>You have been successfully unsubscribed!</p>
            )}
            {responseStatus !== 200 && responseStatus !== 0 && (
                <p style={{
                    color: 'red',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    margin: 0
                }}>Error! Please try again later!</p>
            )}
            <p style={{
                color: '#267DBF',
                fontStyle: 'italic',
                fontSize: '20px',
                margin: 0
            }}>By submitting this form, you will be unsubscribed from additional notifications around the status of your neighborly job</p>
            <div style={{ marginTop: 40 }} />
            {!isNewFooter && <NeighborlyImageLogo />}
            <Column additionalStyles="align-items: center; justify-content: space-between; width: 100%; height: 100%;">
                {isNewFooter ? <BrandFooter trackerBusiness={{}} hasTrackerInfo={false} /> :
                    <ServicesContainer services={relatedServices} />}
            </Column>
        </div>
    );
};

export default UnsubscribePage;

function getInitialJobDataFromAPI(
    currentJobId: any,
    setRequestResponse: React.Dispatch<any>,
    setRequestStatus: React.Dispatch<React.SetStateAction<string>>,
    requestStatus: any,
    requestResponse: any
) {
    ServiceManager.getJobById(currentJobId)
        .then((response: any) => {
            if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.LOADED);
            if (requestResponse === undefined)
                var companyList: any[] = [];
            response.map((item: {}) => (
                companyList.push(Object.values(item)[1])
            ));
            setRequestResponse(companyList);
        })
        .catch((error) => {
            console.log('error:....', error);
            if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.ERROR);
            if (requestResponse === undefined) setRequestResponse(error);
        });
}

function getViewModelFromModel(currentJobId: any, requestStatus: string, requestResponse: []) {
    let relatedServices: any[] = [];
    if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR && requestResponse) {

        relatedServices = requestResponse;
    }

    return { relatedServices };
}

