import React, { useState, useEffect } from 'react';
import API_CONSTANTS from '../constants/api';
import GetServiceProAPI from '../api/serviceProDetail';


const ServiceProListComp: React.FC<any> = (props: any) => {
    // This is mocking data from the API, as long as the API follows the right data format then this should work
    let [requestStatus, setRequestStatus] = useState(API_CONSTANTS.LOADING_STATUSES.LOADING);
    let [requestResponse, setRequestResponse] = useState(null);

    // Get the current JobId from the url

    let currentJobId = props.jobGuid;
    let concept = props.concept;
    // Use effect with an empty list argument acts like componentDidMount
    useEffect(() => {
        getServiceProDetailsAPI(currentJobId, setRequestResponse, setRequestStatus, requestStatus, requestResponse);
    }, []);

    return (
        <div className="emp-container">
            {requestStatus === API_CONSTANTS.LOADING_STATUSES.LOADED && requestResponse && requestResponse.franchiseTechnicianBios.length > 0 && (
                <div className="emp-wrapper thin">
                    <div className="emp-heading">
                        Meet Your Service Pro
                    </div>
                    {requestResponse && requestResponse.franchiseTechnicianBios.map((data: any, index: any, arr: any) => {

                        const { description, displayName, webImage } = data;

                        const webImageGuid = webImage ? webImage.webImageGuid : '4E684958-5A90-4B26-ACDC-1EEB1EF4747A'

                        return (
                            <div key={index}>
                                <div className="info flex">
                                    <img src={`https://api2.dwyergroup.com/Core/v5/WebImages/${webImageGuid}/Render`} alt="Picture of Employee" />
                                    <div className="title">
                                        <h2 className="name">{displayName}</h2>
                                        <strong className="company">{concept.displayText}</strong>
                                        <strong className="corporate">a Neighborly<sup>®</sup> Company</strong>
                                    </div>
                                </div>
                                <div className={index === arr.length - 1 ? 'bio-last' : 'bio'}>
                                    <p>{description ? <span dangerouslySetInnerHTML={{ __html: description }} >

                                    </span> : "At Neighborly, our service professionals are focused on repairing, maintaining, and enhancing your home with service you can count on.  Our professionals will find the solution and provide you quick and reliable customer service.  Every Neighborly brand shares the same R.I.C.H. code of values--respect, integrity, customer focus, and having fun in the process--so you can be confident your home is in good hands."}</p>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            )}

            {requestStatus === API_CONSTANTS.LOADING_STATUSES.LOADING && (
                <div>
                    <h1>Loading!</h1>
                </div>
            )}

            {requestStatus === API_CONSTANTS.LOADING_STATUSES.ERROR && (
                <div>
                    <h1>{requestResponse && requestResponse.toString()}</h1>
                </div>
            )}
        </div>
    );
};

export default ServiceProListComp;

function getServiceProDetailsAPI(
    currentJobId: string,
    setRequestResponse: React.Dispatch<any>,
    setRequestStatus: React.Dispatch<React.SetStateAction<string>>,
    requestStatus: any,
    requestResponse: any,
) {
    GetServiceProAPI.getServicePro(currentJobId)
        .then((response) => {
            if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.LOADED);
            if (requestResponse === null) setRequestResponse(response);
        })
        .catch((error) => {
            console.log('error:....', error);
            if (requestStatus !== API_CONSTANTS.LOADING_STATUSES.ERROR) setRequestStatus(API_CONSTANTS.LOADING_STATUSES.ERROR);
            if (requestResponse === null) setRequestResponse(error);
        });
}





